<template>
    <div class="ai_headline_box">
        <div class="ai_headline_title page_title_">
            Powerful Al Platform Built for Readers
        </div>
        <div class="ai_headline_title_desc">
            Contrimetric is a powerful no-code platform for creating Al-driven business applications
        </div>
        <div class="ai_headline_btn">
            <div class="btn_" @click="() => this.$router.push('/requestDemo')">Start for Free</div>
        </div>
        <div class="ai_headline_four">
            <FourAdv></FourAdv>
        </div>
    </div>
</template>

<script>
import FourAdv from "@/components/fourAdv/index.vue"

export default {
    components: {
        FourAdv
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.ai_headline_box {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .ai_headline_title {
        font-size: 38px;
    }

    .ai_headline_btn {
        display: flex;
        justify-content: center;

        .btn_ {
            background-color: #35393d;
            color: white;
            padding: 8px 20px;
            border-radius: 2px;
            transition: all .2s;

            &:hover {
                background-color: #424547;
                cursor: pointer;
            }
        }
    }

    .ai_headline_four {
        .why_list {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .ai_headline_box {
        .ai_headline_title {
            font-size: 24px;
        }
    }
}
</style>